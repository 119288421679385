// ** Imports createContext function
import React, { createContext } from 'react'

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react'
import { initialAbility } from '../../configs/acl/initialAbility'
import { Ability } from '@casl/ability'

// ** Create Context
export const AbilityContext = createContext()

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)

export function AppProvider(props) {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const existingAbility = userData ? userData.ability : null;


  const [initialability, setability] = React.useState(initialAbility);
  function updateAblity(data) {
    setability(data)
  }
  const ability = new Ability(existingAbility || initialAbility)
  const value = [ability, updateAblity];

  return <AbilityContext.Provider value={value}>{props.children}</AbilityContext.Provider>;
}