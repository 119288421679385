import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import Icon from '@mdi/react';
import { deepCopy } from '../../../utility/Utils';
import { v4 as uuid } from 'uuid'


const initialState = {
    projectInfo: null,
    history: {},
    drawer: true,
    generator: { loading: false, downloadUrl:null, visible: false, requestKey:null },
    items: { polygon:null, polyline:null, spot:null, covers:null, notes: null },
    selectedIds: [],
    defaultProperties: { },
    history: {
        undo: [],
        redo: [],
    },
    templateHistory: [],
    templateVersion:null,
    template: {
        settings: { scale: 1, pageNo: 0, penMode: false, eraserMode: false },//basic setting
        pageWidth: 794.8800000000001,
        pageHeight: 1122.2400000000002,
        name:"Standard Template",
        render: [], //client side render pages of the template
        properties:[{
            pageId: "line-reps",
            reps: true,
            priority: 3,
            applyAllLabels: false,
            elementBuilder: {
                staticElements:{
                    items: [{
                        type: "text",
                        isDynamic: false,
                        freeFormable:true,
                        repsId: uuid(),
                        props: { text: "LINE PHOTOS", fontSize: 60, fontWeight: 700, size: { width: 761.088221418462 } },
                        position: { x: 10, y:10 },
                        desc: "Page Heading"
                    },{
                        type: "img",
                        isDynamic: true,
                        freeFormable:true,
                        dynamicKey: "logo_path",
                        props: { path: "PLACE_URL", size: { height: 50, width: 40 } },
                        position:{ x: 50, y: 10 },
                        desc: "Header Logo"
                    },{
                        type: "img",
                        isDynamic: false,
                        freeFormable:true,
                        props: { path: "PLACE_URL", size: { height: 50, width: 40 } },
                        position:{ x: 50, y: 510 },
                        desc: "Footer Logo"
                    }]
                },
                repElements:{
                    numOfCols: 2,
                    numOfRows: 2,
                    margin: { x: 40, y: 50, top: 105, bottom: 0 },
                    labelSpacing: { withElement: 150, lineHeight: 22 },
                    category: "polyline",
                    forceFreeformable: false,
                    items: [{
                        type: "img",
                        freeFormable:false,
                        isDynamic: true,
                        size: { height: 500, width: 500 },
                        points: {  starting: { x: 2, y: 50 } },
                        dynamicKey: "uri"
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "tag_name",
                        props: { prefix: "Photo Status: ", postfix: "", size: { width: 200 }, fontSize: 14  , fill: 'red'},
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "zone_title",
                        props: { prefix: "Area title: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "zone_area",
                        props: { prefix: "Meas. Area: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "description",
                        props: { prefix: "Rep. Cat: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "createdBy",
                        props: { prefix: "Created By: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "created_at",
                        props: { prefix: "Created At: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    }]
                }
        
            }
        },{//default template (pages will be generated by this information)
            pageId: "spot-reps",
            reps: true,
            priority: 4,
            applyAllLabels: false,
            elementBuilder: {
                staticElements:{
                    items: [{
                        type: "text",
                        isDynamic: false,
                        repsId: uuid(),
                        freeFormable:true,
                        props: { text: "SPOT PHOTOS", fontSize: 60, fontWeight: 500, size: { width: 761.088221418462 } },
                        position: { x: 10, y:10 },
                        desc: "Page Heading"
                    },{
                        type: "img",
                        isDynamic: true,
                        freeFormable:true,
                        dynamicKey: "logo_path",
                        props: { path: "PLACE_URL", size: { height: 50, width: 40 } },
                        position:{ x: 50, y: 10 },
                        desc: "Header Logo"
                    },{
                        type: "img",
                        isDynamic: false,
                        freeFormable:true,
                        props: { path: "PLACE_URL", size: { height: 50, width: 40 } },
                        position:{ x: 50, y: 510 },
                        desc: "Footer Logo"
                    }]
                },
                repElements:{
                    numOfCols:  2,
                    numOfRows: 2,
                    margin: { x: 40, y: 50, top: 105, bottom: 0 },
                    labelSpacing: { withElement: 150, lineHeight: 22 },
                    category: "spot",
                    forceFreeformable: false,
                    items: [{
                        type: "img",
                        freeFormable:false,
                        isDynamic: true,
                        size: { height: 500, width: 500 },
                        points: {  starting: { x: 2, y: 50 } },
                        dynamicKey: "uri"
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "tag_name",
                        props: { prefix: "Photo Status: ", postfix: "", size: { width: 200 }, fontSize: 14 , fill: 'red' },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "zone_title",
                        props: { prefix: "Area title: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "zone_area",
                        props: { prefix: "Meas. Area: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "description",
                        props: { prefix: "Rep. Cat: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "createdBy",
                        props: { prefix: "Created By: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "created_at",
                        props: { prefix: "Created At: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    }]
                }
        
            }
        },{
            pageId: "area-reps",
            reps: true,
            priority: 2,
            applyAllLabels: false,
            elementBuilder: {
                staticElements:{
                    items: [{
                        type: "text",
                        isDynamic: false,
                        repsId: uuid(),
                        freeFormable:true,
                        props: { text: "Area Photos", fontSize: 60, fontWeight: 500, size: { width: 761.088221418462 } },
                        position: { x: 10, y:10 },
                        desc: "Page Heading"
                    },{
                        type: "img",
                        isDynamic: true,
                        freeFormable:true,
                        dynamicKey: "organization_logo",
                        props: { path: "PLACE_URL", size: { height: 50, width: 40 } },
                        position:{ x: 50, y: 10 },
                        desc: "Header Logo"
                    },{
                        type: "img",
                        isDynamic: false,
                        freeFormable:true,
                        props: { path: "PLACE_URL", size: { height: 50, width: 40 } },
                        position:{ x: 50, y: 510 },
                        desc: "Footer Logo"
                    }]
                },
                repElements:{
                    numOfCols: 2,
                    numOfRows: 2,
                    margin: { x: 40, y: 50, top: 105, bottom: 0 },
                    labelSpacing: { withElement: 150, lineHeight: 22 },
                    category: "polygon",
                    forceFreeformable: false,
                    items: [{
                        type: "img",
                        freeFormable:false,
                        isDynamic: true,
                        size: { height: 500, width: 500 },
                        points: {  starting: { x: 2, y: 50 } },
                        dynamicKey: "uri"
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "tag_name",
                        props: { prefix: "Photo Status: ", postfix: "", size: { width: 200 }, fontSize: 14  , fill: 'red'},
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "zone_title",
                        props: { prefix: "Area title: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "zone_area",
                        props: { prefix: "Meas. Area: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "description",
                        props: { prefix: "Rep. Cat: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "createdBy",
                        props: { prefix: "Created By: ", postfix: "", size: { width: 200 }, fontSize: 14 },
                        points: {  starting: { x: 2, y: 50 } }
                    },{
                        type: "text",
                        isLabel: true,
                        freeFormable:false,
                        isDynamic: true,
                        dynamicKey: "created_at",
                        props: { prefix: "Created At: ", postfix: "", size: { width: 200 }, fontSize: 14},
                        points: {  starting: { x: 2, y: 50 } }
                    }]
                }
        
            }
        },{
            "pageId": "cover-page",
            "reps": false,
            "priority": 1,
            "applyAllLabels": false,
            "elementBuilder": {
                "staticElements": {
                    "items": [
                        {
                            "type": "text",
                            "isDynamic": false,
                            "freeFormable": true,
                            "props": {
                                "text": "ASSESSMENT",
                                "fill": "#000",
                                "fontSize": 60,
                                "fontStyle": "500",
                                "align": "left",
                                "letterSpacing": 1,
                                "fontFamily": "Visby CF",
                                "prefix": "",
                                "postfix": "",
                                "size": {
                                    "width": 657.0718666456991
                                },
                                "fontWeight": 500,
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 52.020085026996185,
                                "y": 11.006779800759283
                            },
                            "el_id": "e103a9a8-7884-4241-93ec-1f61f0a660d9",
                            "desc": "Page Heading",
                            "id": "e103a9a8-7884-4241-93ec-1f61f0a660d9"
                        },
                        {
                            "type": "img",
                            "isDynamic": true,
                            "freeFormable": true,
                            "dynamicKey": "organization_logo",
                            "props": {
                                "path": "PLACE_URL",
                                "size": {
                                    "height": 50,
                                    "width": 40
                                },
                                "uri": "",
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 50,
                                "y": 10
                            },
                            "el_id": "7f8bc681-61cb-4f43-8e1a-80451de41af7",
                            "desc": "Header Logo",
                            "id": "7f8bc681-61cb-4f43-8e1a-80451de41af7"
                        },
                        {
                            "type": "rect",
                            "isDynamic": false,
                            "freeFormable": true,
                            "props": {
                                "stroke": "#000000",
                                "strokeWidth": 0,
                                "strokeEnabled": false,
                                "cornerRadius": 12,
                                "fillEnabled": true,
                                "fill": "#edf0f9",
                                "shadowEnabled": true,
                                "shadowColor": "#edf0f9",
                                "shadowOpacity": 0,
                                "shadowBlur": 0,
                                "shadowOffsetX": 0,
                                "shadowOffsetY": 1,
                                "size": {
                                    "height": 457.11650728692433,
                                    "width": 715.0846523348305
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 39.0085119005135,
                                "y": 69.00172603387983
                            },
                            "el_id": "e83c429a-0300-4ecd-8a6b-d9efa886d15f",
                            "desc": "",
                            "id": "e83c429a-0300-4ecd-8a6b-d9efa886d15f"
                        },
                        {
                            "type": "img",
                            "isDynamic": true,
                            "dynamicKey": "selected_cover_photo",
                            "freeFormable": true,
                            "props": {
                                "path": "PLACE_URL",
                                "size": {
                                    "height": 445,
                                    "width": 420
                                },
                                "uri": "dev_team_2/2023-12-19/low_resolution/6581dfdb680b0.jpg",
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 172.01918540651047,
                                "y": 69.092101415981
                            },
                            "el_id": "9031d74b-3724-4810-8c98-d28b362eb207",
                            "desc": "",
                            "id": "9031d74b-3724-4810-8c98-d28b362eb207"
                        },
                        {
                            "type": "rect",
                            "isDynamic": false,
                            "freeFormable": true,
                            "props": {
                                "stroke": "#000000",
                                "strokeWidth": 0,
                                "strokeEnabled": false,
                                "cornerRadius": 12,
                                "fillEnabled": true,
                                "fill": "#edf0f9",
                                "shadowEnabled": true,
                                "shadowColor": "#edf0f9",
                                "shadowOpacity": 0,
                                "shadowBlur": 0,
                                "shadowOffsetX": 0,
                                "shadowOffsetY": 1,
                                "size": {
                                    "height": 48.999999999999815,
                                    "width": 713.0850460666192
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 40.00772443693421,
                                "y": 581.1307470664366
                            },
                            "el_id": "1d246108-4cbd-496a-ba6c-7a9dad38ebdd",
                            "desc": "",
                            "id": "1d246108-4cbd-496a-ba6c-7a9dad38ebdd"
                        },
                        {
                            "type": "rect",
                            "isDynamic": false,
                            "freeFormable": true,
                            "props": {
                                "stroke": "#000000",
                                "strokeWidth": 0,
                                "strokeEnabled": false,
                                "cornerRadius": 12,
                                "fillEnabled": true,
                                "fill": "#edf0f9",
                                "shadowEnabled": true,
                                "shadowColor": "#edf0f9",
                                "shadowOpacity": 0,
                                "shadowBlur": 0,
                                "shadowOffsetX": 0,
                                "shadowOffsetY": 0,
                                "size": {
                                    "height": 46.9999999999996,
                                    "width": 715.0858335301988
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 39.00693697335424,
                                "y": 653.135062151137
                            },
                            "el_id": "581e9779-47a8-41bf-ad1e-9310279e7987",
                            "desc": "",
                            "id": "581e9779-47a8-41bf-ad1e-9310279e7987"
                        },
                        {
                            "type": "rect",
                            "isDynamic": false,
                            "freeFormable": true,
                            "props": {
                                "stroke": "#000000",
                                "strokeWidth": 0,
                                "strokeEnabled": false,
                                "cornerRadius": 12,
                                "fillEnabled": true,
                                "fill": "#edf0f9",
                                "shadowEnabled": true,
                                "shadowColor": "#edf0f9",
                                "shadowOpacity": 0,
                                "shadowBlur": 0,
                                "shadowOffsetX": 0,
                                "shadowOffsetY": 1,
                                "size": {
                                    "height": 221.06041118581402,
                                    "width": 713.0850460666187
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 40.007330705143715,
                                "y": 721.1380827104272
                            },
                            "el_id": "f86d1675-ce13-4c60-a4f5-3936b625b0c4",
                            "desc": "",
                            "id": "f86d1675-ce13-4c60-a4f5-3936b625b0c4"
                        },
                        {
                            "type": "text",
                            "isDynamic": true,
                            "freeFormable": true,
                            "dynamicKey": "project_title",
                            "props": {
                                "text": "New project 150",
                                "fill": "#000",
                                "fontSize": 20,
                                "fontStyle": "500",
                                "align": "left",
                                "letterSpacing": 1,
                                "fontFamily": "Visby CF",
                                "prefix": "Project Name: ",
                                "postfix": "",
                                "size": {
                                    "height": 50,
                                    "width": 551.0803585469415
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 75.99019435094007,
                                "y": 598.1311923898801
                            },
                            "el_id": "37672ab2-ff74-452b-b189-3c44cb674c69",
                            "desc": "",
                            "id": "37672ab2-ff74-452b-b189-3c44cb674c69"
                        },
                        {
                            "type": "text",
                            "isDynamic": true,
                            "freeFormable": true,
                            "dynamicKey": "address",
                            "props": {
                                "text": "Denver",
                                "fill": "#000",
                                "fontSize": 20,
                                "fontStyle": "500",
                                "align": "left",
                                "letterSpacing": 1,
                                "fontFamily": "Visby CF",
                                "prefix": "Address: ",
                                "postfix": "",
                                "size": {
                                    "height": 50,
                                    "width": 326.04497562509823
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 78.01346727421371,
                                "y": 666.1365655232971
                            },
                            "el_id": "7bdd016b-264d-426b-96f8-97db58c97fda",
                            "desc": "",
                            "id": "7bdd016b-264d-426b-96f8-97db58c97fda"
                        },
                        {
                            "type": "text",
                            "isDynamic": true,
                            "freeFormable": true,
                            "dynamicKey": "project_description",
                            "props": {
                                "text": "N/A",
                                "fill": "#000",
                                "fontSize": 20,
                                "fontStyle": "500",
                                "align": "left",
                                "letterSpacing": 1,
                                "fontFamily": "Visby CF",
                                "prefix": "Description: ",
                                "postfix": "",
                                "size": {
                                    "height": 50,
                                    "width": 650.0959270325523
                                },
                                "builderKey": "staticElements"
                            },
                            "position": {
                                "x": 80.9867323346155,
                                "y": 745.120275476934
                            },
                            "el_id": "68eae0b1-eb25-4956-ad0c-8833f79b67eb",
                            "desc": "",
                            "id": "68eae0b1-eb25-4956-ad0c-8833f79b67eb"
                        }
                    ]
                },
                "repElements": null
            }
        }]
    },
 
    defaultShapes: {
        genericProps: {
            "type": "",
            "position": {"x": 50,"y": 50},
            "size": {"width": 150,"height": 150},
            "el_id": "",
            "freeFormable":true,
            "isDynamic": false,
            "desc": ""
        },
        line: { shapeProps: { points:[], stroke: "#000000", strokeWidth: 3, tension: 0.5, lineCap: "round", lineJoin: "round", tool: "pen", builderKey: "staticElements" } },
        drawing: { shapeProps: { points:[], stroke: "#000000", strokeWidth: 1, tension: 0.5, lineCap: "round", lineJoin: "round" } },
        rect: { shapeProps: { "stroke": "#000000", "strokeWidth": 0, "strokeEnabled": false, "cornerRadius": 12, "fillEnabled": true, "fill": "#bf2d2d", "shadowEnabled":true, "shadowColor": "#edf0f9", "shadowOpacity":0, "shadowBlur": 0 , "shadowOffsetX": 0, "shadowOffsetY": 0 } },
        img: { shapeProps: { "uri": "" } },
        text: { shapeProps: {"text": "Write a new text...","fill": "#000","fontSize": 20,"fontStyle": "500", "align":"left", "letterSpacing":1, "fontFamily": "Visby CF", "prefix": "", "postfix": "", size: { width: 200 } } },
        table: { shapeProps: {"fill": "#000","fontSize": 20,"fontStyle": "500", "align":"left", "letterSpacing":1, "fontFamily": "Visby CF", "prefix": "", "postfix": "", size: { width: 200 }, items: [],"stroke": "#000000", "strokeWidth": 0, "strokeEnabled": false, "cornerRadius": 12 } }
    }
}

export const pdfBuilderSlice = createSlice({
    name: 'pdfBuilder',
    initialState,
    reducers: {
        loadProjectData: (state, action) => {
            // const { info, area, line, spot, covers, notes } = action.payload
            state.projectInfo= action.payload.info
            delete action.payload.info
            state.items = {...state.items, ...action.payload}
        },
        
        updateTemplate: (state, action) => {
            state.template= {...state.template, ...action.payload}
        },
        updateTemplateSetting: (state, action) => {
            state.template.settings= {...state.template.settings, ...action.payload}
        },
        switchPage: (state, action) => {
            state.selectedIds=[]
            state.template.settings.pageNo=action.payload
        },
        updatePages: (state, action) => {
            const currentPage = action.payload[state.template.settings.pageNo]
            let _properties= deepCopy(state.template.properties)
            // console.log("currentPage",currentPage, "_properties",_properties)
            let _currProperties=_properties.find(el => el.pageId === currentPage.pageId) ?? {elementBuilder: {staticElements: { items: [] },repElements:null }}
            if(currentPage.reps === false){
                _currProperties["elementBuilder"]["staticElements"]["items"]= currentPage.elements
            }else if(currentPage.reps === true){
                const uniqueCombinations = new Set();
                const resultChunks = Object.values(currentPage.elements.reduce((acc, item) => {
                    const combination = `${item["element type"]}_${item.isDynamic}_${item.dynamicKey}`;
                    if (!uniqueCombinations.has(combination)) {
                      uniqueCombinations.add(combination);
                      acc[item.builderKey] = acc[item.builderKey] || [];
                      acc[item.builderKey].push(item);
                    } else if (!item.dynamicKey) {
                      acc[item.builderKey] = acc[item.builderKey] || [];
                      acc[item.builderKey].push(item);
                    }
                    return acc;
                  }, {}));
                _currProperties.elementBuilder.repElements.items=resultChunks[0].filter(el => el.props.builderKey === "repElements")
                _currProperties.elementBuilder.staticElements.items=resultChunks[0].filter(el => el.props.builderKey === "staticElements")
                
            }
            // console.log("called update pages", _properties)
            state.template.properties=_properties
            state.template.render = action.payload
        },
        updateSelection: (state, action) => {
            state.selectedIds=action.payload
        },
        toggleDrawer: (state, action) => {
            state.drawer= action.payload
        },
        handleScale: (state, action) => {
            const { useCase, value } = action.payload
            const scale= useCase === 0 ? value : useCase === 1 ? (state.template.settings.scale * value) : (state.template.settings.scale/value)
            state.template.settings.scale = scale
        },
        updatePDFGenerator: (state, action) => {
            state.generator={...state.generator, ...action.payload}
        },
        handleUndo: (state, action) => {
            state.history.undo = action.payload
        },
        handleRedo: (state, action) => {
            state.history.redo = action.payload
        },
        handletemplateHistory: ((state, action) => {
            state.templateHistory = action.payload
        }),
        handleTemplateVersion:((state,action) => {
            state.templateVersion = action.payload
        })
    }
})

// Action creators are generated for each case reducer function
export const { loadProjectData,updateTemplate, switchPage, updatePages, updateSelection, toggleDrawer, handleScale, updatePDFGenerator, updateTemplateSetting, handleUndo, handleRedo, handletemplateHistory  , handleTemplateVersion} = pdfBuilderSlice.actions

export default pdfBuilderSlice.reducer