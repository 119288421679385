// ** Initial State
const initialState = {
  zoneIds:[],
  isFiltered: false,
  visibleZone: null,
  areaFilters: {
    shape: [],
    repCat: [],
    rating: [],
    paserRating: [],
    status: [],
  },
  includeComment: true,
  loadProject: false,
  showZoneComments: false,
  showZoneChat: false,
  showZonePhotos: false,
  repairCategories: [],  
  
  selectedFilteredId : null,
  viewMode:false,
  
  statusOptions: {
    pickerVisibility: false,
    color: ['rgba(227,13,13,1)', 'rgba(227,13,13,0.5)'],
    options: [],
    statusModal: false,
    optionBtn: false,
    optValue: '',
    optFbId: '',
    updation: false,
    loading: false,
  },
  assetOptions: { visibility: false, assets: [], pId: '', uuids: [], project: null },
    shareInfo: {},
    shareable: false,
    organization: {},
    allZonesComment: {},
    projectFiltersList: {}
}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_REPAIR_CATEGORY': {
      return {
        ...state,
        repairCategories: action.payload
      }
    }
    case 'SET_FILTERED_ID':
      return {
        ...state,
        selectedFilteredId:action.payload,
      }
      case 'SET_VIEW_MODE':
      return {
          ...state,
          project:action.payload,
        }
    case 'SET_ZONES':
      return {
        ...state,
        zoneIds:action.zoneIds,
      }  
    case 'SET_ZONE_IS_FILTERED': 
      return {
        ...state,
        isFiltered:action.payload 
      }
    case 'SET_VISIBLE_ZONE': 
      return {
        ...state,
        visibleZone:action.payload.visibleZone,
      }
    case 'SET_PROJECT_FILTER_LIST':
        return {
          ...state,
          projectFiltersList: action.projectFiltersList,
        }
    case 'SET_AREA_FILTER': 
      return {
        ...state,
        areaFilters:action.payload,
      }
    
    case 'SET_INCLUDE_COMMENT': 
      return {
        ...state,
        includeComment:action.payload,
      }
    case 'SET_PROJECT_DATA': 
      return {
        ...state,
        loadProject: action.payload,
      }  
    case 'SET_ZONE_COMMENT': 
      return {
        ...state,
        showZoneComments: action.payload,
      }   
    case 'SET_ZONE_CHAT': 
      return {
        ...state,
        showZoneChat: action.payload,
      }
    
    case 'SET_ZONE_PHOTO': 
      return {
        ...state,
        showZonePhotos: action.payload,
      }
         case 'SET_STATUS_OPTION':
        return {
          ...state,
          statusOptions: action.payload,
        }
      case 'SET_ASSET_OPTION':
        return {
          ...state,
          assetOptions: action.payload,
        }
      case 'SET_SHARE_INFO':
        return {
          ...state,
          shareInfo: action.payload,
        }
      case 'SET_SHAREABLE':
        return {
          ...state,
          shareable: action.payload,
        }
      case 'SET_ORGANIZATION':
        return {
          ...state,
          organization: action.payload,
        }
      case 'SET_ALL_ZONES_COMMENT':
        return {
          ...state,
          allZonesComment: action.payload,
        } 
      case 'CLEAR_AREA_FILTER':
        return { 
          ...state,
          areaFilters: {
            shape: [],
            repCat: [],
            rating: [],
            paserRating: [],
            status: [],
          },
          visibleZone: null,
        }   
    default:
      return state
  }
}

export default projectReducer