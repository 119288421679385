import moment from 'moment'
// ** Initial State
const initialState = {
  updateData: false,
  suggestions: [],
  templateVersion:null,
  bookmarks: [],
  query: '',
  selectedFilterVal: {
    organizations: [{value: "", label: "All Organizations"}],
    users: [{value: "", label: "All Users"}],
plans: [{value: "", label: "All Plans"}],
  },
  filters: {
    dateRange: [],
    organizations: [],
    users: [],
    page:1,
    pageSize:10,
    plans: [],
  },

}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATE_VERSION':
      return {...state, templateVersion: action.payload}
    case 'SET_FILTER_VAL':
      return {...state, selectedFilterVal: action.payload}
    case 'UPDATE_DATA':
      return {...state, updateData: action.payload}
    case 'SET_FILTERS':
      return {...state, filters: action.payload}
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val }
    case 'GET_BOOKMARKS':
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case 'UPDATE_BOOKMARKED':
      let objectToUpdate
      // ** find & update object
      state.suggestions.find(item => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.id)

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }

      return { ...state }
    default:
      return state
  }
}

export default navbarReducer
