const logoRed =  require("@src/assets/images/logo/sos-logo.png").default
const logoBlack =  require("@src/assets/images/logo/logo.png").default
//Template config options
const themeConfig = {
  app: {
    appName: "SpotOnSite",
    appLogoImage: require("@src/assets/images/logo/logo.svg").default,
    appDarkLogo: require("@src/assets/images/logo/logo-landscape.png").default,
    logo:{
      icon: logoBlack,
      full: require("@src/assets/images/logo/logo-landscape.png").default
    },
    url: `https://${process.env.REACT_APP_API_ENV == "api" ? "dashboard" : process.env.REACT_APP_API_ENV == "dev"  ? "devdb" : "stagingdb"
     }.spotonsiteapp.com`,
    apiUrl: `https://${process.env.REACT_APP_API_ENV}.${process.env.REACT_APP_BASE_URL}/public/v1.1/`,
    // apiUrl: "http://192.168.5.40/sos-backend-api/public/v1.1/",
    cdnUrl: "https://cdn.spotonsiteapp.com",
    storageUrl: `https://${process.env.REACT_APP_API_ENV}.${process.env.REACT_APP_BASE_URL}/storage/app/public`,
    uploadPath: `https://${process.env.REACT_APP_API_ENV}.${process.env.REACT_APP_BASE_URL}/storage/app/uploads`,
    mobileAppUrl: "https://apps.apple.com/us/app/id1580155527",
    IpDataUrl: "https://api.ipdata.co",
    IpDataApiKey: process.env.REACT_APP_IPDATA_KEY,
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    geoCodeApiKey:process.env.REACT_APP_GEOCODE_API_KEY,
    firebase: {
      apiKey: process.env.REACT_APP_FB_APIKEY,
      authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
      databaseURL: `https://spotonsiteapp-ios-${process.env.REACT_APP_API_ENV == 'api' ? 'default-rtdb' : 'pd'}.firebaseio.com/`,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: "spotonsiteapp-ios.appspot.com",
      messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER,
      appId: process.env.REACT_APP_FB_APP_ID,
      measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
    },
    paymentStatus: [
      { text: "Initiate", color: "light-info" },
      { text: "Active", color: "light-success" },
      { text: "Past Due", color: "light-warning" },
      { text: "Unpaid", color: "light-danger" },
      { text: "Canceled", color: "light-danger" },
      { text: "Incomplete", color: "light-warning" },
      { text: "Incomplete Expired", color: "light-danger" },
      { text: "Trialing", color: "light-warning" },
      { text: "Approved", color: "light-info" },
    ],
    imageStatus: [
      { label: "Photos with no status", value: 1 },
      { label: "All Photos", value: 2 },
      { label: "Before, during & after", value: 3 },
      { label: "Before & after", value: 4 },
      { label: "Before & during", value: 5 },
      { label: "Before only", value: 6 }
    ],
    imageStaticWidth: [
      {Width: 272.4400, Height:262.4400  , numOfRows:1},
      {Width: 272.4400, Height:262.4400  , numOfRows:2},
      {Width: 211.29333, Height:227.2933 , numOfRows:3},
      {Width: 272.4400, Height:262.4400  , numOfRows:4}, 
      {Width: 211.29333, Height:227.2933 , numOfRows:6}
    ],
  },
  plan:{
    "enterprisePlan": {
        "key": "advancedpro",
        "title": "Advanced Pro - User Tier Based",
        "img": logoBlack,
        "subtitle": "Solution for big organizations",
        "monthlyPrice": 129,
        "price":{
            "monthly": {
                "id": "price_1OyKBQCGlAxiwFBuomcGHeLM",
                "rate": 200
            },
            "yearly": {
                "id": "price_1OyKBQCGlAxiwFBuHOmVJbUk",
                "rate": 2160
            }
        },
        "planBenefits": [
            "Unlimited Projects",
            "Mark & Measure",
            "Upload Photos/Videos to specific areas",
            "Team Share",
            "Interactive Dashboard",
            "PDF Reports",
            "Assignable Color Repair Categories",
            "Moveable Pins",
            "Edit and Create Projects in your Dashboard"
        ],
        "popular": false
    }
  },
  layout: {
    isRTL: false,
    skin: "bordered", // light, dark, bordered, semi-dark
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: true,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "sticky", // static , sticky , floating, hidden
      backgroundColor: "white", // BS color options [primary, success, etc]
    },
    footer: {
      type: "static", // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
  },
  meaurementVars: [
    { label: "square feet", value: "ft2", nickName: "sq ft" },
    { label: "square yards", value: "yd2", nickName: "sq yd" },
    { label: "acres", value: "a", nickName: "acres" },
    { label: "square inches", value: "in2", nickName: "sq in" },
    { label: "square meters", value: "m2", nickName: "sq meter" },
    { label: "hectares", value: "ha", nickName: "hect" },
    { label: "square kilometers", value: "km2", nickName: "sq km" },
    { label: "meter", value: "m", nickName: "meter" },
    { label: "kilometers", value: "km", nickName: "km" },
    { label: "centimeters", value: "cm", nickName: "cm" },
    { label: "millimeters", value: "mm", nickName: "mm" },
    { label: "miles", value: "mi", nickName: "miles" },
    { label: "seamiles", value: "sm", nickName: "sm" },
    { label: "feet", value: "ft", nickName: "ft" },
    { label: "inches", value: "in", nickName: "in" },
    { label: "yards", value: "yd", nickName: "yd" },
    { label: "unit", value: "unit", nickName: "unit" },
    { label: "each", value: "each", nickName: "each" },
  ],
  formats: {
    date: "MM-DD-YYYY",
    time: "HH-mm A",
  },
  defaultAbility: [{ action: "manage", subject: "guest" },{ action: "manage", subject: "error" }],

  mapIcon:
    "M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z",
};
export default themeConfig;
