import { createSlice } from '@reduxjs/toolkit'
const initialState = {

	selectMessages: [],
	msgCopied: false,
	// multipleDelete: false,
	chatWidgetOpen: false,
	readByMsg: '',
	leftSidebar: false,
	rightSidebar: false,
	chatProject: null,
	notificationStatus: typeof Notification !== "undefined" ? Notification.permission : "denied",
	zoneModal: false,
	myChats: [],
	loading: false,
	projectSidebar: false,
	zoneSidebar: false,
	chatType: 'project',
	activeChat: {},
  	unreadMessages: {},
}

export const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
    setSelectMessage: (state, action) => {
			state.selectMessages = action.payload.selectMessages
		},
		setCopySucceed: (state, action) => {
			state.msgCopied = action.payload.msgCopied
		},
		setChatWidgetOpen: (state, action) => {
			state.chatWidgetOpen = action.payload.chatWidgetOpen
		},
		setMsgReadBy: (state, action) => {
			state.readByMsg = action.payload.readByMsg
		},
		setLeftSidebar: (state, action) => {
			state.leftSidebar = action.payload.leftSidebar
		},
		setRightSidebar: (state, action) => {
			state.rightSidebar = action.payload.rightSidebar
		},
		setChatProjectId: (state, action) => {
			state.chatProject = action.payload
		},
		
		setNotificationStatus: (state, action) => {
			state.notificationStatus = action.payload
		},
		setZoneModal: (state, action) => {
			state.zoneModal = action.payload
		},
		setMyChats: (state, action) => {
			state.myChats = action.payload
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setProjectSidebar: (state, action) => {
			state.projectSidebar = action.payload
		},
		setZoneSidebar: (state, action) => {
			state.zoneSidebar = action.payload
		},
		setChatType: (state, action) => {
			state.chatType = action.payload
		},
		setActiveChat: (state, action) => {
			state.activeChat = action.payload
		},
		setUnreadChatMessages: (state, action) => {
			state.unreadMessages = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setSelectMessage,
	setCopySucceed,
	setChatWidgetOpen,
	setMsgReadBy,
	setLeftSidebar,
	setRightSidebar,
	setChatProjectId,
	setNotificationStatus,
	setZoneModal,
	setMyChats,
	setLoading,
	setProjectSidebar,
	setZoneSidebar,
	setChatType,
	setActiveChat,
  setUnreadChatMessages,

	} = chatSlice.actions

export default chatSlice.reducer
